<template>
    <div class="size">
        <div class="size-item flex" v-for="(item,idx) in size" :key="idx">
            <div style="max-width:160px;margin-right:8px">
                <el-input v-model="item.name" @change="onChange" placeholder="自定义填写"></el-input>
            </div>
            <el-button v-if="idx==0" @click="add()" type="text">
                <span>+</span> 继续添加
            </el-button>
            <el-button v-if="idx>0" @click="del(idx)" type="text" style="color:#FF4D4F">
                <i class="iconfont icon-zu9"></i> 删除
            </el-button>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: "dimension",
            event: "change"
        },
        props: ['dimension'],
        data() {
            return {
                size: [],
            }
        },
        watch: {
            dimension: {
                handler() {
                    // this.updata();
                },
                deep: true
            },
            // size: {
            //     handler() {
            //         this.onChange();
            //     },
            //     deep: true
            // },
        },
        computed: {},
        created () {
            this.updata();
        },
        methods: {
            updata() {
                if (this.dimension && this.dimension.length>0) {
                    this.dimension.forEach(e => {
                        this.size.push({name:e});
                    });
                } else {
                    this.size = [{name: ""}];
                }
                // console.log(this.size);
            },
            onChange() {
                let dimension = this.size.reduce((pre,cur)=>{
                    if (cur && cur.name) {
                        pre.push(cur.name);
                    }
                    return pre;
                },[]);
                console.log(dimension);
                this.$emit("change", dimension);
            },
            add() {
                if (this.size[this.size.length-1].name) {
                    this.size.push({name: ""});
                    this.onChange();
                } else {
                    this.$message.error("填写完才能继续添加");
                }
            },
            del(index) {
                this.$confirm('确认删除吗', "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    closeOnClickModal: false,
                    type: "warning",
                }).then(() => {
                    this.size.splice(index,1);
                    this.$message.success("删除成功");
                    this.onChange();
                });
            },
        }
    }
</script>
<style scoped>
    .size-item {
        margin-bottom: 16px;
    }
    .size-item:last-child {
      margin-bottom: 0;
    }
</style>